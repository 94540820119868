<!-- 租户产品规格 -->
<template>
  <div class="">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 flex justify-between xs:flex-col 2xl:flex-row align-items-center"
    >
      <div class="flex flex-row align-items-center">
        产品规格
        <div class="tips text-gray-400 text-xs ml-0.5">
          点击产品规格名称可快速创建账单
        </div>
      </div>
      <div class="flex justify-between xl:flex-col 2xl:flex-row">
        <el-dropdown size="small" split-button @click="createBillLicense">
          <div>
            <i class="el-icon-document-add mr-1"></i>添加License产品规格
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="createBillProjectDialog">
                <i class="el-icon-document-add mr-1"></i>添加可设置项目
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="overflow-y-auto mt-4 grid gap-3 grid-cols-2">
      <div
          class="border h-16 px-4 flex justify-between align-items-center rounded-md cursor-pointer hover:bg-gray-50 relative"
          v-for="(item, index) in billProjectList"
          :key="index"
          @mouseenter="itemMouseenter(item)"
          @mouseleave="itemMouseleave()"
      >
        <div class="title flex justify-between" @click="quickCreateBill(item)">
          <div class="text text-gray-700 text-sm font-bold">
            <p class="truncate w-36">
              {{ item.projectName }}
            </p>
          </div>
        </div>
        <div class="text-xs">{{ item.unitPrice }}元</div>
        <div
            v-if="curHoverItem.id === item.id"
            class="z-10 absolute right-1 flex-row flex"
        >
          <div class="">
            <el-tooltip
                class="item"
                effect="dark"
                :content="
                item.bindBillProjectNum > 0 ? '账单已被使用，无法编辑' : '编辑'
              "
                placement="top"
            >
              <el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="editBill(item)"
              ></el-button>
            </el-tooltip>
          </div>
          <div class="ml-1">
            <el-tooltip
                class="item"
                effect="dark"
                :content="
                item.bindBillProjectNum > 0 ? '账单已被使用，无法删除' : '删除'
              "
                placement="top"
            >
              <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteBillLicense(item)"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
        class="empty w-full text-xs text-gray-500 h-20 flex align-items-center justify-content-center"
        v-if="billProjectList.length === 0"
    >
      暂无数据
    </div>
    <editBillLicenseDialog
        ref="editBillLicenseDialog"
        @on-submit="editBillLicenseOnSubmit"
    ></editBillLicenseDialog>
    <editBillProjectDialog
        ref="editBillProjectDialog"
        @on-submit="getData"
    ></editBillProjectDialog>
    <quickCreateBillDialog
        ref="quickCreateBillDialog"
        @on-submit="quickCreateBillOnSubmit"
    ></quickCreateBillDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">

import editBillLicenseDialog from 'components/bill/editBillLicenseDialogV2.vue'
import editBillProjectDialog from 'components/bill/editBillProjectDialogV2.vue'
import quickCreateBillDialog from 'components/bill/quickCreateBillDialog.vue'

export default {
  name: 'agentChargeInfo',
  data () {
    return {
      billProjectList: [],
      requestParams: {
        uniqueId: ''
      },
      curHoverItem: {}
    }
  },
  props: {
    customerInfo: {
      type: Object
    }
  },
  components: {
    editBillLicenseDialog,
    quickCreateBillDialog,
    editBillProjectDialog
  },
  computed: {},
  watch: {
    customerInfo (obj) {
      if (obj.uniqueId) {
        this.requestParams.uniqueId = obj.uniqueId
        this.getData()
      }
    }
  },
  methods: {
    quickCreateBillOnSubmit () {
      this.$emit('quickCreateBill-on-submit')
      this.getData()
    },
    getData () {
      this.$api.findAll('billProject', this.requestParams).then(res => {
        this.billProjectList = res.data
      })
    },
    createBillLicense () {
      if (!this.customerInfo.uniqueId) {
        this.$message.error('租户信息为空')
        return
      }
      this.$refs.editBillLicenseDialog.show({
        uniqueId: this.customerInfo.uniqueId
      })
    },
    editBill (item) {
      if (item.bindBillProjectNum > 0) {
        return
      }
      if (item.projectType === 2) {
        this.$refs.editBillProjectDialog.edit(item)
      } else if (item.projectType === 4) {
        this.$refs.editBillLicenseDialog.edit(item)
      }
    },
    createBillProjectDialog () {
      if (!this.customerInfo.uniqueId) {
        this.$message.error('租户信息为空')
        return
      }
      this.$refs.editBillProjectDialog.show({
        uniqueId: this.customerInfo.uniqueId
      })
    },
    editBillLicenseOnSubmit () {
      this.getData()
    },
    itemMouseenter (item) {
      this.curHoverItem = item
    },
    itemMouseleave () {
      this.curHoverItem = {}
    },
    deleteBillLicense (item) {
      if (item.bindBillProjectNum > 0) {
        return
      }
      this.$api.deleteBatch('billProject', [item.id]).then(() => {
        this.getData()
      })
    },
    quickCreateBill (billProject) {
      this.$refs.quickCreateBillDialog.show(billProject)
    }
  },
  mounted () {
  }
}
</script>
