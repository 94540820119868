<!-- 租户型号管理 -->
<template>
  <div>
    <el-card
        shadow="never"
        class="device-model-list"
        :body-style="{ height: '100%' }"
    >
      <AppTable
          ref="AppTable"
          :customTableData="customTableData"
          :tableColumns="tableColumns"
      >
        <template #left-operator>
          <div class="h-8">
            <el-button-group>
              <el-button
                  v-for="(item, index) in deviceTypeList"
                  :key="index"
                  size="mini"
                  round
                  class="select-none"
                  :type="deviceType === item.deviceType ? 'primary' : ''"
                  @click="changeDeviceType(item.deviceType)"
              >
                {{ item.deviceTypeName }}
              </el-button>
            </el-button-group>
          </div>
        </template>
        <template #systemDeviceModel="{ row }">
          {{ row.factoryName }} / {{ row.brandName }} / {{ row.deviceModel }}
        </template>
        <template #customerDeviceModel="{ row }">
          <div v-for="(item, index) in row.deviceModelJointList" :key="index">
            {{ item.prodFactory }} / {{ item.prodBrand }} / {{ item.prodModel }}
          </div>
        </template>
        <template #operator="{ row }">
          <el-button @click="edit(row)" size="mini"> 编辑型号</el-button>
        </template>
      </AppTable>
    </el-card>
    <editorDeviceModelDialog
        ref="editorDeviceModelDialog"
        @on-submit="editorDeviceModelOnSubmit"
    ></editorDeviceModelDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import editorDeviceModelDialog from 'components/client/editorDeviceModelDialogV2'

export default {
  data () {
    return {
      clientId: '',
      customTableData: [],
      tableColumns: [
        {
          label: '原始型号',
          type: 'customSlot',
          slotName: 'systemDeviceModel'
        },
        {
          label: '展示型号',
          type: 'customSlot',
          slotName: 'customerDeviceModel'
        },
        {
          width: '120px',
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      deviceType: '',
      deviceTypeList: []
    }
  },
  components: {
    editorDeviceModelDialog
  },
  methods: {
    getDeviceTypeFindAll () {
      this.$api.findAll('sysDeviceType').then(res => {
        this.deviceTypeList = [{
          deviceTypeName: '全部',
          deviceType: ''
        }, ...res.data]
      })
    },
    getDeviceModelJoint () {
      this.$api.findAll('deviceModelJoint', {
        customerId: this.clientId,
        deviceType: this.deviceType
      }).then(res => {
        this.customTableData = res.data
      })
    },
    edit (item) {
      this.$refs.editorDeviceModelDialog.show({ deviceObj: item })
    },
    changeDeviceType (type) {
      this.deviceType = type
      this.getDeviceModelJoint()
    },
    editorDeviceModelOnSubmit () {
      this.getDeviceModelJoint()
    }
  },
  activated () {
    this.clientId = this.$route.query.id
    this.getDeviceTypeFindAll()
    this.getDeviceModelJoint()
  }
}
</script>
