<!-- 快速创建账单 -->
<template>
  <el-dialog
      title="快速创建账单"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="billObj"
        label-width="160px"
        label-position="left"
        size="small"
        cass="overflow-auto"
        :rules="rules"
        v-if="billObj.billDetailList[0]"
    >
      <el-form-item label="当前选中规格" prop="projectName">
        {{ billObj.billDetailList[0].projectName || '' }}
      </el-form-item>
      <el-form-item label="规格时效" prop="feeCycle">
        {{ billObj.billDetailList[0].feeCycle || '' }} 天
      </el-form-item>
      <el-form-item label="规格价格" prop="unitPrice">
        {{ billObj.billDetailList[0].unitPrice || '-' }} 元
      </el-form-item>
      <el-form-item label="授权数量" prop="quantity">
        <el-input-number
            v-model="billObj.billDetailList[0].quantity"
            :min="1"
            label="请输入数量"
            @change="computedRowMoney"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="总金额" prop="totalFee">
        <el-input-number
            v-model="billObj.billDetailList[0].totalFee"
            :min="0"
            label="请输入总金额"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="支付方式" prop="payType">
        <el-select v-model="billObj.payType" placeholder="请选择支付方式">
          <el-option
              v-for="(item, index) in $store.getters.payType"
              :key="index"
              :value="item.type"
              :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账单备注" prop="remark">
        <el-input
            type="textarea"
            :rows="4"
            placeholder="账单备注"
            v-model="billObj.remark"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="支付状态" prop="payFlag">
        <el-checkbox v-model="payFlag">是否已支付状态</el-checkbox>
      </el-form-item>
    </el-form>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">快速创建</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      billObj: {
        uniqueId: '',
        payType: 1,
        remark: '',
        billDetailList: []
      },
      rules: {
        payType: [
          {
            required: true,
            message: '请选择支付方式',
            trigger: 'blur'
          }
        ]
      },
      payFlag: false
    }
  },
  methods: {
    close () {
      this.billObj = {
        uniqueId: '',
        payType: 1,
        remark: '',
        billDetailList: []
      }
      this.payFlag = false
      this.dialogVisible = false
    },
    show (billProject) {
      this.billObj.uniqueId = billProject.uniqueId
      this.billObj.billDetailList = [{
        billProjectId: billProject.id,
        totalFee: 0,
        projectType: billProject.projectType,
        quantity: 1,
        projectName: billProject.projectName,
        feeCycle: billProject.feeCycle,
        unitPrice: billProject.unitPrice
      }]
      this.computedRowMoney()
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.save('bill', {
            ...this.billObj,
            ...{
              payFlag: this.payFlag ? 1 : 0
            }
          }).then(() => {
            this.$message.success('操作成功')
            this.$emit('on-submit')
            this.close()
          })
        }
      })
    },
    computedRowMoney () {
      this.billObj.billDetailList[0].totalFee = this.billObj.billDetailList[0].unitPrice * this.billObj.billDetailList[0].quantity
    }
  },
  mounted () {
  }
}
</script>
