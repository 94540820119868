<!-- License 使用情况 -->
<template>
  <div>
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 flex justify-between"
    >
      <div>
        License 使用情况
        <i class="cursor-pointer el-icon-refresh-right" @click="getData"></i>
      </div>
      <div>
        <span class="text-xs text-gray-500"
        >总数量：{{ LicenseInfo.quantity }}</span
        >
        <span class="text-xs text-gray-500 ml-2"
        >已使用：{{ LicenseInfo.assignNum }}</span
        >
        <span class="text-xs text-gray-500 ml-2"
        >剩余可用数量：{{ LicenseInfo.remainNum }}</span
        >
      </div>
    </div>
    <div class="border-b pb-2">
      <el-checkbox v-model="displayed"
      ><span class="text-xs text-gray-500">仅显示可用账单</span></el-checkbox
      >
    </div>
    <div class="h-96 overflow-y-auto mt-4">
      <template v-if="licenseBillProjectList.length > 0">
        <div
            class="license-item my-4"
            v-for="(item, index) in licenseBillProjectList"
            :key="index"
        >
          <div class="title flex justify-between">
            <div class="text text-gray-700 text-sm font-bold">
              {{ item.projectName }}
            </div>
          </div>
          <div>
            <span class="text-xs text-gray-500"
            >总数量：{{ item.quantity }}</span
            >
            <span class="text-xs text-gray-500 ml-2"
            >已使用：{{ item.assignNum }}</span
            >
            <span class="text-xs text-gray-500 ml-2"
            >剩余可用数量：{{ item.remainNum }}</span
            >
          </div>
          <el-progress
              :show-text="false"
              class="mt-1"
              :percentage="(item.assignNum / item.quantity) * 100"
          ></el-progress>
        </div>
      </template>
      <template v-else>
        <div class="text-center text-gray-500">暂无数据</div>
      </template>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      LicenseInfo: {},
      displayed: true
    }
  },
  props: {
    customerInfo: {
      type: Object
    }
  },
  computed: {
    licenseBillProjectList () {
      return this.LicenseInfo.licenseBillProjectList || []
    }
  },
  watch: {
    customerInfo () {
      if (this.customerInfo.uniqueId) {
        this.getData()
      }
    },
    displayed () {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.$api.customerDevice.getLicenseUse({
        uniqueId: this.customerInfo.uniqueId,
        displayed: this.displayed ? 1 : 2
      }).then(res => {
        this.LicenseInfo = res.data
      })
    }
  },
  mounted () {
  }
}
</script>
