<!-- 客户存储容器信息 -->
<template>
  <div>
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
    >
      存储信息
    </div>
    <div
        class="mt-4 data bg-blue-50 h-auto border-blue-100 border-dotted border-2 rounded-md p-3 flex flex-col cursor-pointer"
    >
      <span class="text-base font-sans">
        <i class="el-icon-coin text-blue-800"></i>
        {{ customerContainerInfo.containerName || '' }}
      </span>
      <p class="text-xs text-gray-500 mt-2">
        存储区域
        <span class="text-gray-700 ml-1">{{
            customerContainerInfo.cloudFactoryAreaName
          }}</span>
      </p>
      <p class="text-xs text-gray-500 mt-1">
        容器大小
        <span class="text-gray-900 ml-1"
        >{{ customerContainerInfo.totalContainerSize }}GB</span
        >
      </p>
      <p class="text-xs text-gray-500 mt-1">
        生命周期
        <span class="text-gray-900 ml-1">60天</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    customerContainerInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {},
  mounted () {
  }
}
</script>
