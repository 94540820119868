<!-- 修改客户ID -->
<template>
  <el-dialog
    title="修改客户ID"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="close"
    top="5vh"
    width="30%">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="UUID">
        <el-input v-model="form.uniqueId"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">修改</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less">
</style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      form: {
        id: '',
        uniqueId: ''
      }
    }
  },
  methods: {
    close () {
      this.dialogVisible = false
      this.form = {
        id: '',
        uniqueId: ''
      }
    },
    show ({
      id,
      uniqueId
    }) {
      this.form.id = id
      this.form.uniqueId = uniqueId
      this.dialogVisible = true
    },
    submit () {
      if (this.form.id && this.form.uniqueId) {
        this.$api.customer.updateUniqueId(this.form).then(() => {
          this.$emit('on-submit', this.form)
          this.close()
        })
      } else {
        this.$message.error('参数错误')
        return false
      }
    }
  },
  mounted () {
  }
}
</script>
