<!--  -->
<template>
  <el-dialog
      title="编辑展示别名"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        class=""
        ref="form"
        :model="deviceModel"
        label-width="80px"
        size="small"
        :rules="rules"
    >
      <el-form-item prop="prodFactory">
        <template #label>
          <span class="text-xs"> 厂家名称 </span>
        </template>
        <el-input v-model="deviceModel.prodFactory"></el-input>
      </el-form-item>
      <el-form-item prop="prodBrand">
        <template #label>
          <span class="text-xs"> 品牌名称 </span>
        </template>
        <el-input v-model="deviceModel.prodBrand"></el-input>
      </el-form-item>
      <el-form-item prop="prodModel">
        <template #label>
          <span class="text-xs"> 设备型号 </span>
        </template>
        <el-input v-model="deviceModel.prodModel"></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <span class="text-xs"> API支持情况 </span>
        </template>
        <el-select
            v-model="deviceFunc"
            multiple
            placeholder="请选择"
            class="w-full"
        >
          <el-option
              v-for="item in sysDeviceFunctionalList"
              :key="item.prop"
              :label="item.label"
              :value="item.prop"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button @click="submit">保存</el-button>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false,
      deviceModel: {}, // 假型号数据
      originalModel: {}, // 原始型号数据
      sysDeviceFunctionalList: [],
      deviceFunc: [],
      customerId: '',
      rules: {
        prodFactory: [
          {
            required: true,
            message: '请输入厂家名称',
            trigger: 'blur'
          }
        ],
        prodBrand: [
          {
            required: true,
            message: '请输入品牌名称',
            trigger: 'blur'
          }
        ],
        prodModel: [
          {
            required: true,
            message: '请输入设备型号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['deviceFunctionalList'])
  },
  methods: {
    close () {
      this.initData()
      this.dialogVisible = false
    },
    initData () {
      this.deviceModel = {}
      this.originalModel = {}
      this.sysDeviceFunctionalList = []
      this.deviceFunc = []
    },
    /**
     * @param original 原始型号数据
     * @param fake 假型号数据
     */
    show ({
      original,
      fake,
      customerId
    }) {
      this.deviceModel = fake ? JSON.parse(JSON.stringify(fake)) : {
        prodFactory: '',
        prodBrand: '',
        prodModel: ''
      }
      this.originalModel = original ? JSON.parse(JSON.stringify(original)) : {}
      this.customerId = customerId
      // 处理API可选选项
      this.handlerDeviceFuncList()
      this.handlerDeviceFunc()
      this.dialogVisible = true
    },
    /**
     * 处理API可选选项
     */
    handlerDeviceFuncList () {
      this.sysDeviceFunctionalList = []
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      for (const key in this.originalModel) {
        if (funcList.indexOf(key) > -1 && this.originalModel[key] === 1) {
          const result = this.deviceFunctionalList.find(item => item.prop === key)
          this.sysDeviceFunctionalList.push(result)
        }
      }
    },
    /**
     * 处理已选中API
     */
    handlerDeviceFunc () {
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      for (const key in this.deviceModel) {
        const item = this.deviceModel[key]
        if (item === 1 && funcList.indexOf(key) > -1) {
          this.deviceFunc.push(key)
        }
      }
    },
    handlerSubmitDeviceFunc () {
      const funcList = this.deviceFunctionalList.map(item => item.prop)
      funcList.forEach(key => {
        this.deviceModel[key] = 0
      })
      this.deviceFunc.forEach(key => {
        this.deviceModel[key] = 1
      })
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handlerSubmitDeviceFunc()
          if (!this.deviceModel.id) {
            // 新增的情况下
            this.deviceModel.factoryName = this.originalModel.factoryName
            this.deviceModel.brandName = this.originalModel.brandName
            this.deviceModel.deviceModel = this.originalModel.deviceModel
            this.deviceModel.customerId = this.customerId
          }
          this.$api.deviceModelJoint.edit(this.deviceModel).then(res => {
            this.$message.success('编辑成功')
            this.$emit('on-submit', this.deviceModel)
            this.close()
          })
        }
      })
    }
  },
  mounted () {
  }
}
</script>
