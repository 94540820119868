<!-- 编辑客户信息弹窗 -->
<template>
  <el-dialog
      title="编辑代理基本信息"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="50%"
  >
    <customerInfo ref="customerInfo" edit></customerInfo>
    <el-button @click="submit" class="mt-4" type="primary">提交</el-button>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import customerInfo from 'components/client/customerInfo'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false
    }
  },
  components: {
    customerInfo
  },
  computed: {
    ...mapGetters(['customerInfo'])
  },
  methods: {
    close () {
      this.dialogVisible = false
    },
    show (customerInfo) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.customerInfo.setCustomerInfoForm(customerInfo)
      })
    },
    async submit () {
      if (!await this.checkForm()) {
        return false
      }
      this.$api.save('customerInfo', {
        id: this.customerInfo.id,
        customerInfo: this.customerInfo
      }).then(() => {
        this.$store.commit('clearCustomerInfo')
        this.$emit('on-submit')
        this.close()
      })
    },
    async checkForm () {
      try {
        await this.$refs.customerInfo.validateForm()
        return true
      } catch (e) {
        return false
      }
    }
  },
  mounted () {
  }
}
</script>
