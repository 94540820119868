<!-- 服务器信息 -->
<template>
  <div>
    <div
      class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
    >
      服务器信息
    </div>
    <div class="dev-info flex flex-row mt-4">
      <div class="info text-xs leading-8 text-gray-600 ml-4 flex-1">
        <div
          class="item flex flex-row"
          v-if="customerNodeInfo.deployType !== 3"
        >
          <div class="">访问地址:</div>
          <div class="value ml-2">
            <a
              class="text-blue-900"
              target="_blank"
              :href="`${handlerUrl(customerNodeInfo.domainName)}`"
            >
              {{ customerNodeInfo.domainName }}
              <span class="text-xs ml-2">
                <i class="el-icon-view"></i>
                点击访问平台
              </span>
            </a>
          </div>
        </div>
        <div class="item flex flex-row">
          <div class="label">部署方式:</div>
          <div class="value ml-2">
            {{
              customerNodeInfo.deployType === 1 ? 'SASS' : customerNodeInfo.deployType === 2 ? '云独立部署' : '离线独立部署'
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    customerNodeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    /**
     * 自动补全URL地址
     * @param url
     * @returns {*|string}
     */
    handlerUrl (url) {
      if (!url) {
        return ''
      }
      if (url.indexOf('https://') > -1 || url.indexOf('http://') > -1) {
        return url
      } else {
        return 'http://' + url
      }
    }
  },
  mounted () {
  }
}
</script>
