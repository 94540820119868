<!-- 代理基本信息 -->
<template>
  <div>
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
    >
      客户基本信息
      <div class="float-right cursor-pointer" @click="edit" v-if="editorBtn">
        <i class="el-icon-edit"></i>
        编辑
      </div>
    </div>

    <div class="dev-info flex flex-row mt-4">
      <div class="info text-xs leading-8 text-gray-600 ml-4 flex-1">
        <div class="item flex flex-row">
          <div class="label">客户名称:</div>
          <div class="value ml-2">
            {{ customerInfo.name }}
          </div>
        </div>
        <div class="item flex flex-row">
          <div class="label">客户ID:</div>
          <div class="value ml-2">
            {{ customerInfo.uniqueId }}
          </div>
          <el-button class="ml-1" type="text" size="mini" @click="showUpdateUuidDialog">点击修改ID</el-button>
        </div>
        <div class="item flex flex-row">
          <div class="label">帐号:</div>
          <div class="value ml-2">
            {{ customerInfo.account }}
          </div>
        </div>
        <div class="item flex flex-row">
          <div class="label">联系人:</div>
          <div class="value ml-2">
            {{ customerInfo.contactName || '暂未填写联系人' }}
          </div>
        </div>
        <div class="item flex flex-row">
          <div class="label">联系方式:</div>
          <div class="value ml-2">
            {{ customerInfo.contactPhone || '暂未填写联系方式' }}
          </div>
        </div>
        <div class="item flex flex-row">
          <div class="label">支付方式:</div>
          <div class="value ml-2">
            {{ customerInfo.payType === 1 ? '对公转账' : '-' }}
          </div>
        </div>
        <div class="item flex flex-row">
          <div class="label">创建时间:</div>
          <div class="value ml-2">
            {{ customerInfo.createDtm }}
          </div>
        </div>
      </div>
    </div>
    <customerInfoEditDialog
        @on-submit="onSubmit"
        ref="customerInfoEditDialog"
    ></customerInfoEditDialog>
    <updateUuidDialog
        @on-submit="onSubmit"
        ref="updateUuidDialog"
    ></updateUuidDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import customerInfoEditDialog from './customerInfoEditDialog'
import updateUuidDialog from 'components/client/updateUuidDialog.vue'

export default {
  data () {
    return {}
  },
  components: {
    customerInfoEditDialog,
    updateUuidDialog
  },
  props: {
    customerInfo: {
      type: Object
    },
    editorBtn: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  methods: {
    edit () {
      this.$refs.customerInfoEditDialog.show(JSON.parse(JSON.stringify(this.customerInfo)))
    },
    showUpdateUuidDialog () {
      this.$refs.updateUuidDialog.show(this.customerInfo)
    },
    onSubmit () {
      this.$emit('on-submit')
    }
  },
  mounted () {
  }
}
</script>
