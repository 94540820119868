<!-- 客户详情-基础信息 -->
<template>
  <div class="grid grid-cols-4 gap-4 grid-rows-8" style="height: 80vh">
    <el-card shadow="never" class="grid col-span-3 row-span-4">
      <clientDefaultInfo
          @on-submit="onEditSubmit"
          ref="clientDefaultInfo"
          :customerInfo="customerInfo"
          editorBtn
      ></clientDefaultInfo>
    </el-card>
    <el-card shadow="never" class="grid row-span-2">
      <customerNodeInfo
          @on-submit="onEditSubmit"
          ref="customerNodeInfo"
          :customerNodeInfo="customerNode"
      ></customerNodeInfo>
    </el-card>
    <el-card
        shadow="never"
        class="grid row-span-2"
        v-if="customerNode.deployType !== 3"
    >
      <customerContainerInfo
          @on-submit="onEditSubmit"
          ref="customerContainerInfo"
          :customerContainerInfo="customerContainer"
      ></customerContainerInfo>
    </el-card>
    <el-card shadow="never" class="grid col-span-2 row-span-3">
      <customerLicenseInfo
          @on-submit="onEditSubmit"
          ref="customerLicenseInfo"
          :customerInfo="customerInfo"
      ></customerLicenseInfo>
    </el-card>
    <el-card shadow="never" class="grid col-span-2 row-span-3">
      <customerChargeInfo
          ref="customerChargeInfo"
          :customerInfo="customerInfo"
      ></customerChargeInfo>
    </el-card>
  </div>
</template>
<style lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import clientDefaultInfo from 'components/client/clientDefaultInfo.vue'
import customerNodeInfo from 'components/client/customerNodeInfo.vue'
import customerContainerInfo from 'components/client/customerContainerInfo.vue'
import customerLicenseInfo from 'components/client/customerLicenseInfo.vue'
import customerChargeInfo from 'components/client/customerChargeInfo.vue'
import { mapGetters } from 'vuex'

const Clipboard = require('clipboard')
export default {
  data () {
    return {}
  },
  components: {
    clientDefaultInfo,
    customerNodeInfo,
    customerContainerInfo,
    customerLicenseInfo,
    customerChargeInfo
  },
  computed: {
    ...mapGetters(['portAuthType'])
  },
  props: {
    customerContainer: {},
    customerInfo: {},
    customerNode: {},
    customerPanel: {}
  },
  methods: {
    copy (el) {
      const clipboard = new Clipboard(`#${el}`)
      clipboard.on('success', () => {
        this.$message.success('已复制到粘贴板')
        clipboard.destroy()
      })
    },
    onchangeContainer () {
      this.$emit('onGetData')
    },
    editCustomerInfo () {
      this.$refs.customerInfoEditDialog.show(JSON.parse(JSON.stringify(this.customerInfo)))
    },
    editOnSubmit () {
      this.$emit('onGetData')
    },
    handlerPortAuthList (list) {
      if (list && list.length > 0) {
        const str = []
        this.portAuthType.map(item => {
          if (list.indexOf(item.type) > -1) {
            str.push(item.label)
          }
        })
        return str.join('、')
      }
    },
    onEditSubmit () {
      this.$emit('onGetData')
    }
  },
  mounted () {
  }
}
</script>
