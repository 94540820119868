<!-- 编辑租户设备型号 -->
<template>
  <el-dialog
      title="编辑设备型号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="80%"
  >
    <div class="grid grid-cols-12 gap-3" v-loading="isLoading">
      <div class="border p-4 col-span-5">
        <deviceDefaultInfo :deviceObj="deviceObj"></deviceDefaultInfo>
      </div>
      <div class="col-span-1 justify-content-center align-items-center flex">
        <i class="text-4xl el-icon-right"></i>
      </div>
      <div class="col-span-6">
        <div
            class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2"
        >
          设备展示别名
          <el-button @click="addModel" size="mini" class="ml-4">新增</el-button>
        </div>
        <AppTable
            ref="AppTable"
            :customTableData="modelList"
            :tableColumns="tableColumns"
            :refreshBtn="false"
        >
          <template #customerDeviceModel="{ row }">
            <i
                v-if="row.isDefault === 0"
                @click="setDefault(row)"
                class="text-lg el-icon-star-off cursor-pointer text-yellow-500"
            ></i>
            <i
                v-if="row.isDefault === 1"
                class="text-lg el-icon-star-on cursor-pointer text-yellow-500"
            ></i>
            {{ row.prodFactory }} / {{ row.prodBrand }} / {{ row.prodModel }}
          </template>
          <template #operator="{ row }">
            <el-button @click="edit(row)" size="mini"> 编辑型号</el-button>
            <el-button @click="deleteModel(row)" size="mini" type="danger">
              删除
            </el-button>
          </template>
        </AppTable>
      </div>
    </div>
    <div class="tips text-xs text-gray-400 mt-2">
      <i class="el-icon-info"></i>
      别名展示规则为 设备单独设置 > 租户设置 > 设备管理设置 > 系统
    </div>
    <editorDeviceModelFormDialog
        @on-submit="editorDeviceOnSubmit"
        ref="editorDeviceModelFormDialog"
    ></editorDeviceModelFormDialog>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import deviceDefaultInfo from '../device/deviceDefaultInfo.vue'
import editorDeviceModelFormDialog from 'components/client/editorDeviceModelFormDialogV1.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false,
      deviceObj: {},
      customerId: null,
      isLoading: false,
      modelList: [],
      tableColumns: [
        {
          label: '展示型号',
          type: 'customSlot',
          slotName: 'customerDeviceModel'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['deviceFunctionalList'])
  },
  components: {
    deviceDefaultInfo,
    editorDeviceModelFormDialog
  },
  props: {
    type: {
      // 1.代理端全局设备型号 2.租户设备型号 3.对IMEI单独设置
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  methods: {
    close () {
      this.dialogVisible = false
      this.deviceObj = {}
      this.customerDeviceObj = {}
      this.deviceFunc = []
      this.sysDeviceFunctionalList = []
    },
    async show ({ deviceObj }) {
      this.isLoading = true
      this.dialogVisible = true
      this.deviceObj = deviceObj
      // 根据设备真实型号，获取别名列表
      this.modelList = await this.getDeviceModelList(deviceObj)
      // 业务处理结束
      this.isLoading = false
    },
    /**
     * 根据设备真实型号，获取别名列表
     * @param deviceModel
     * @param deviceType
     * @returns {Promise<unknown>}
     */
    getDeviceModelList ({
      deviceModel
    }) {
      return new Promise(resolve => {
        this.$api.sysDevice.getDeviceModelList({
          deviceModel,
          customerId: this.customerId
        }).then(res => {
          resolve(res.data)
        })
      })
    },
    edit (item) {
      this.$refs.editorDeviceModelFormDialog.show({
        original: this.deviceObj,
        fake: item,
        customerId: this.customerId
      })
    },
    async editorDeviceOnSubmit (deviceModel) {
      this.modelList = await this.getDeviceModelList(this.deviceObj)
      this.$emit('on-submit')
    },
    addModel () {
      this.$refs.editorDeviceModelFormDialog.show({
        original: this.deviceObj,
        customerId: this.customerId
      })
    },
    setDefault (item) {
      this.$api.deviceModelJoint.setDefault({
        id: item.id
      }).then(async (res) => {
        this.$message.success('设置成功')
        this.modelList = await this.getDeviceModelList(this.deviceObj)
      })
    },
    deleteModel (item) {
      this.$confirm('请注意：已关联选择该型号的设备，会自动变为默认值', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$api.deviceModelJoint.delete({
          id: item.id
        }).then(async (res) => {
          this.$message.success('删除成功')
          this.modelList = await this.getDeviceModelList(this.deviceObj)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  },
  activated () {
    this.customerId = this.$route.query.id
  }
}
</script>
