<!-- 客户基础信息 -->
<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span class="text-base">客户基本信息</span>
    </div>
    <el-form
        ref="form"
        :model="customerInfoForm"
        label-width="160px"
        label-position="left"
        size="small"
        class="overflow-auto"
        :rules="rules"
        style="height: calc(100vh - 300px)"
    >
      <el-form-item label="客户名称" prop="name">
        <el-input
            v-model="customerInfoForm.name"
            placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <!--      <el-form-item label="LOGO" prop="logo">-->
      <!--        <el-upload-->
      <!--            class="h-48 w-48 border-gray-400 border-dashed border"-->
      <!--            action="#"-->
      <!--            :show-file-list="false"-->
      <!--            :http-request="logoUploader"-->
      <!--        >-->
      <!--          <img class="p-1 object-contain h-48 w-48" v-if="customerInfoForm.logo" :src="customerInfoForm.logo">-->
      <!--          <i v-else class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"></i>-->
      <!--        </el-upload>-->
      <!--        <div class="text-xs leading-6 font-sans antialiased text-gray-400 mt-1 select-none">-->
      <!--          <p>建议上传：</p>-->
      <!--          <p>1. 图片大小：200PX * 50PX</p>-->
      <!--          <p>2. 图片格式：PNG</p>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="登录封面" prop="coverUrl">-->
      <!--        <el-upload-->
      <!--            class="h-48 w-48 border-gray-400 border-dashed border"-->
      <!--            action="#"-->
      <!--            :show-file-list="false"-->
      <!--            :http-request="coverUrlUploader"-->
      <!--        >-->
      <!--          <img class="p-1 object-contain h-48 w-48" v-if="customerInfoForm.coverUrl" :src="customerInfoForm.coverUrl" alt="">-->
      <!--          <i v-else class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"></i>-->
      <!--        </el-upload>-->
      <!--        <div class="text-xs leading-6 font-sans antialiased text-gray-400 mt-1 select-none">-->
      <!--          <p>建议上传：</p>-->
      <!--          <p>1. 图片大小：1920PX * 1080PX</p>-->
      <!--          <p>2. 图片格式：JPG、JPGE</p>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="帐号" prop="account">-->
      <!--        <el-input v-model="customerInfoForm.account" placeholder="请输入帐号"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="联系人" prop="contactName">
        <el-input
            v-model="customerInfoForm.contactName"
            placeholder="请输入联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contactPhone">
        <el-input
            v-model="customerInfoForm.contactPhone"
            :disabled="edit"
            placeholder="请输入联系方式"
        ></el-input>
        <div class="tips text-xs text-gray-400 mt-2">
          <i class="el-icon-info"></i>
          该联系方式将作为租户端的顶级管理员登录账号。
        </div>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!edit">
        <el-input
            v-model="customerInfoForm.password"
            placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="passwordConfirm" v-if="!edit">
        <el-input
            v-model="customerInfoForm.passwordConfirm"
            placeholder="请输入确认密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系地址">
        <el-input
            v-model="customerInfoForm.contactAddress"
            placeholder="请输入联系地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="登录端口">
        <el-checkbox-group v-model="portAuthList">
          <el-checkbox
              v-for="(item, index) in portAuthType"
              :key="index"
              :label="item.type"
          >
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select v-model="customerInfoForm.payType">
          <el-option :value="1" label="对公转账"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'
import oss from '@/utils/oss'

export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.customerInfoForm.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      portAuthList: [],
      input: '',
      customerInfoForm: {},
      rules: {
        name: {
          required: true,
          message: '请输入客户名称',
          trigger: 'blur'
        },
        contactName: {
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        },
        contactPhone: {
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        },
        account: {
          required: true,
          message: '请输入帐号',
          trigger: 'blur'
        },
        logo: {
          required: true,
          message: '请上传LOGO',
          trigger: 'blur'
        },
        coverUrl: {
          required: true,
          message: '请上传封面图',
          trigger: 'blur'
        },
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            validator: validatePass,
            trigger: 'blur'
          }],
        passwordConfirm: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['customerInfo', 'portAuthType'])
  },
  methods: {
    handlerCustomerInfo () {
      this.customerInfoForm = JSON.parse(JSON.stringify(this.customerInfo))
      // console.log('customerInfoForm', this.customerInfoForm)
      // console.log('customerInfo', this.customerInfo)
      // console.log(' || this.customerInfoForm.portAuthList', this.customerInfoForm.portAuthList)
      // if (!this.customerInfoForm.portAuthList || this.customerInfoForm.portAuthList.length === 0) {
      //   this.portAuthList = []
      // } else {
      //   this.portAuthList = this.customerInfoForm.portAuthList
      // }
    },
    async logoUploader (item) {
      const { file } = item
      this.customerInfoForm.logo = await oss.upload(file)
      this.$refs.form.validateField('logo')
    },
    async coverUrlUploader (item) {
      const { file } = item
      this.customerInfoForm.coverUrl = await oss.upload(file)
      this.$refs.form.validateField('coverUrl')
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        // 使用联系人电话作为最高账号使用
        this.customerInfoForm.account = this.customerInfoForm.contactPhone
        this.customerInfoForm.portAuthList = this.portAuthList
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$store.commit('setCustomerInfo', this.customerInfoForm)
            resolve(valid)
          } else {
            this.$message.error('请检查客户基本信息表单内容')
            this.$emit('changeActive', '2')
            reject(valid)
          }
        })
      })
    },
    setCustomerInfoForm (customerInfoForm) {
      this.portAuthList = customerInfoForm.portAuthList
      this.customerInfoForm = customerInfoForm
    }
  },
  mounted () {
    this.handlerCustomerInfo()
  }
}
</script>
