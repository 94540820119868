<!-- 客户详情页 -->
<template>
  <div>
    <blockTitle title="客户信息"></blockTitle>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="基础信息" name="info">
        <clientInfo
            :key="composeKey"
            :customerContainer="customerContainer"
            :customerInfo="customerInfo"
            :customerNode="customerNode"
            :customerPanel="customerPanel"
            @onGetData="getData"
        ></clientInfo>
      </el-tab-pane>
      <el-tab-pane label="设备信息" name="device">
        <clientDevice
            :type="2"
            :key="composeKey"
            :customerInfo.sync="customerInfo"
            :customerNode="customerNode"
            ref="clientDevice"
        ></clientDevice>
      </el-tab-pane>
      <el-tab-pane label="型号管理" name="deviceModel">
        <clientDeviceModel ref="clientDeviceModel"></clientDeviceModel>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style lang="less" rel="stylesheet/less">
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #fff3e6;
  color: #ff8807;
  border-bottom-color: #ff8807;
  border-bottom-width: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover,
.el-tabs__item:hover {
  color: #ff8807;
}
</style>
<script type="text/ecmascript-6">
import clientInfo from '@/components/client/clientInfoV2'
import clientDevice from '@/components/client/clientDeviceV3'
import clientDeviceModel from '@/components/client/clientDeviceModel'

export default {
  name: 'ClientDetails',
  data () {
    return {
      activeName: 'info',
      customerContainer: {},
      customerInfo: {},
      customerNode: {},
      customerPanel: {},
      refresh: false,
      composeKey: 1
    }
  },
  components: {
    clientInfo,
    clientDevice,
    clientDeviceModel
  },
  methods: {
    getData () {
      this.$api.select('customerInfo', this.$route.query.id).then(res => {
        const {
          customerContainer,
          customerInfo,
          customerNode,
          customerPanel
        } = res.data
        this.customerContainer = customerContainer
        this.customerInfo = customerInfo
        this.customerNode = customerNode
        this.customerPanel = customerPanel
      })
    },
    initPage () {
      this.composeKey++
      this.customerContainer = {}
      this.customerInfo = {}
      this.customerNode = {}
      this.customerPanel = {}
      this.activeName = 'info'
    }
  },
  activated () {
    if (this.$route.meta.isBack) {
      // console.log('不刷新')
    } else {
      // console.log('刷新')
      this.initPage()
      this.$nextTick(() => {
        this.getData()
      })
    }
    this.$route.meta.isBack = false
  },
  mounted () {
  }
}
</script>
